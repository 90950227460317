document.addEventListener('turbo:load', () => {
  initializeMegaMenu();
});

document.addEventListener('turbo:render', () => {
  initializeMegaMenu(); // Re-attach event listeners after Turbo replaces content
});

function initializeMegaMenu() {
  const $megaMenu = $('.c-megamenu');
  const $mobileMenu = $('.c-mobile-menu');
  const $expandButton = $megaMenu.find('.c-button.c-button--expand');
  const $searchButton = $megaMenu.find('button.c-button.c-button--secondary');
  const $closeButton = $megaMenu.find('.c-button--close');
  const $searchBar = $megaMenu.find('.c-megamenu__search-input');
  const $menuCategories = $megaMenu.find('.c-megamenu__categories');
  const $mobileCategories = $mobileMenu.find('.c-megamenu__categories');
  const $hamburger = $mobileMenu.find('.c-mobile-menu__hamburger');

  // Ensure event listeners are not duplicated
  $megaMenu.off('click');
  $mobileMenu.off('click');

  // Add .c-button--search class to search button to fix padding issue
  $searchButton.addClass('c-button--search');

  ///////////////////////
  // Mobile Navigation //
  ///////////////////////

  // Expands menu when hamburger is clicked
  $hamburger.off('click').on('click', () => {
    if (!$mobileCategories.hasClass('c-megamenu__categories--revealed')) {
      $mobileCategories.addClass('c-megamenu__categories--revealed');
      $mobileCategories.
        css({ opacity: 1 }).
        show(75).
        animate({ opacity: 1, duration: 100 });
    } else {
      $mobileCategories.removeClass('c-megamenu__categories--revealed');
      $mobileCategories.
        css({ opacity: 0 }).
        hide(75).
        animate({ opacity: 0, duration: 100 });
    }
  });

  /////////////////
  // Search Bar //
  ////////////////

  const closeSearchBar = function() {
    $searchButton.prop('disabled', true);
    $closeButton.css('opacity', 0);
    $expandButton.css('z-index', 2);
    $searchButton.css({
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    });
    $menuCategories.
      css('z-index', 1).
      animate(
        { opacity: 1 },
        { duration: 200 },
      );
    $searchBar.
      animate(
        { opacity: 0 },
        { duration: 200 },
      );
    $searchBar.delay(210).
      queue(function() {
        $(this).css('z-index', -1);
        $(this).dequeue();
      });
  };

  $searchButton.prop('disabled', true);

  // Hides search bar and brings back megamenu if anywhere else is clicked.
  $(document).off('click.menuState').on('click.menuState', event => {
    const $target = $(event.target);
    if (!$target.closest('.c-megamenu__search').length) {
      closeSearchBar();
    }
  });

  // Pulls up search bar and hides megamenu when search button is clicked.
  $expandButton.off('click').on('click', () => {
    $searchButton.prop('disabled', false);
    $closeButton.css('opacity', 1);
    $expandButton.css('z-index', -1);
    $searchButton.css({
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    });
    $menuCategories.
      css('z-index', -1).
      animate(
        { opacity: 0 },
        { duration: 200 },
      );
    $searchBar.
      css('z-index', 1).
      animate(
        { opacity: 1 },
        { duration: 200 },
      );
    $searchBar.focus();
  });

  // Hides search bar and brings back megamenu when 'X' is clicked.
  $closeButton.off('click').on('click', () => {
    closeSearchBar();
  });

  ////////////////////////
  // Animated Sub-menus //
  ////////////////////////

  function closeCategory(menuType) {
    // only proceed if there exists an open menu to close
    if (!menuType.find('.c-megamenu__category--revealed').length) {
      return;
    }
    menuType.find('.c-megamenu__category--revealed').each(function () {
      const $category = $(this);
      const $categoryContent = $($category.data('target'));

      setTimeout(() => {
        $categoryContent.animate(
          { opacity: 0 },
          {
            duration: 75,
            complete: function() {
              $categoryContent.hide();
            },
          },
        );
        $category.removeClass('c-megamenu__category--revealed');
      }, 75);
    });
  }

  function saveMegaMenuState() {
    const openMenus = [];
    $('.c-megamenu__category--revealed').each(function () {
      openMenus.push($(this).data('target'));
    });
    sessionStorage.setItem('megaMenuState', JSON.stringify(openMenus));
  }

  $mobileMenu.find('.c-megamenu__category').each(function () {
    const $category = $(this);
    const $categoryContent = $($category.data('target'));

    $category.off('click').on('click', e => {
      const $target = $(e.target);
      if (!$category.hasClass('c-megamenu__category--revealed')) {
        closeCategory($mobileMenu);
        $category.addClass('c-megamenu__category--revealed');
        $categoryContent.
          css({ opacity: 0 }).
          show(75).
          animate({ opacity: 1, duration: 100 });
      } else if (!$target.closest('.c-megamenu__category-content').length) {
        // Category is currently revealed and user clicked
        // off of the category-content... (close it)
        closeCategory($mobileMenu);
      }
      saveMegaMenuState();
    });
  });

  // Handle Mega Menu category clicks and save state
  $megaMenu.find('.c-megamenu__category').each(function () {
    const $category = $(this);
    const $categoryContent = $($category.data('target'));

    $category.off('click').on('click', e => {
      const $target = $(e.target);
      // Allow clicking outside the menu context to close it
      $('body').off('click.menuState').on('click.menuState', function (bodyEvent) {
        if ($(bodyEvent.target).closest('.c-megamenu').length) { return; }
        closeCategory($megaMenu);
        $(this).off('click.menuState');
      });

      if (!$category.hasClass('c-megamenu__category--revealed')) {
        // if parent is not opened, so  close other
        // opened submenus (should only ever be 1)
        closeCategory($megaMenu);
        $category.addClass('c-megamenu__category--revealed');
        $categoryContent.
          css({ opacity: 0 }).
          show(75).
          animate({ opacity: 1, duration: 100 });
      } else if (!$target.closest('.c-megamenu__category-content').length) {
        // Category is currently revealed and user clicked
        // off of the category-content... (close it)
        closeCategory($megaMenu);
      }
      saveMegaMenuState();
    });
  });
}
