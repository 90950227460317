import { Controller } from '@hotwired/stimulus';

/*
   This controller is used to load the invisible reCAPTCHA widget in a form
   using javascript. By using javascript to load the reCAPTCHA widget, we can
   deal more cleanly with Rails' turbo frames.

   This controller is used by inserting the following in the form:

   ```
   <div data-controller="load-invisible-recaptcha"
        data-load-invisible-recaptcha-sitekey="YOUR_SITE_KEY"
   </div>
   ```

   To make this easier, there's a helper method called `invisible_recaptcha_controllertag`
   that does the above for you.

   Using javascript to load the reCAPTCHA widget is done by adding the following
   in the page header:

   ```
   <script src="https://www.google.com/recaptcha/api.js?render=explicit" async defer></script>
   ```

   Next we render the reCAPTCHA widget in the form by calling the grecaptcha.render()
   method. See https://developers.google.com/recaptcha/docs/invisible

   When Rails turbo removes a page containing a reCAPTCHA widget, the reCAPTCHA widget is
   cleaned up automatically, thereby avoiding the javascript errors.

   WARNING: when testing (rspec) you need to make sure the recapcha is loaded before
   trying to submit the form. You can do this by adding the following in your test:

   ```
     expect(page).to(have_selector(".grecaptcha-badge"))
   ```

   Or you can use the helper method `wait_for_recaptcha` that does this for you.

 */

// Connects to data-controller="load-invisible-recaptcha"
export default class extends Controller {
  static values = {
    sitekey: String,
  };

  connect() {
    this.parentForm = this.element.closest('form');
    if (this.recaptchaIsLoaded()) {
      this.renderRecaptcha();
      return;
    }
    const interval = setInterval(() => {
      if (this.recaptchaIsLoaded()) {
        clearInterval(interval);
        this.renderRecaptcha();
      }
    }, 200);
  }

  disconnect() {
    // Just clear the container to remove the reCAPTCHA widget
    while (this.element.firstChild) {
      this.element.removeChild(this.element.lastChild);
    }
  }

  renderRecaptcha() {
    grecaptcha.render(this.element, {
      sitekey: this.sitekeyValue,
      callback: this.submitForm.bind(this),
      size: 'invisible',
    });
    this.parentForm.querySelectorAll('input[type="submit"]').forEach(button => {
      button.addEventListener('click', event => {
        if (this.parentForm.checkValidity()) {
          event.preventDefault();
          return grecaptcha.execute();
        }
      });
    });
  }

  submitForm() {
    this.parentForm.requestSubmit();
  }

  recaptchaIsLoaded() {
    return (typeof grecaptcha !== 'undefined') &&
      (grecaptcha !== null) &&
      grecaptcha.render &&
      (this.element.childElementCount === 0);
  }
}
