import { Controller } from '@hotwired/stimulus';

//
// This controller is used to show/hide elements when an
// event is triggered (e.g. a click event).
//
// Usage
// -----
//
// If we want to show an element with id="my-element" and
// hide an element with id="my-other-element" when an event
// is triggered, we can use the following
// HTML:
//
// <div data-controller="dynamic-content">
//   <a href="#"
//      data-action="click->dynamic-content#update"
//      data-dynamic-content-selection-class="my-class"
//      data-dynamic-content-show-param="#my-element"
//      data-dynamic-content-hide-param="#my-other-element">
//      Click me
//   </a>
//   <div id="my-element" style="display: none;">
//    This is the element that will be shown
//   </div>
//   <div id="my-other-element">
//    This is the element that will be hidden
//   </div>
// </div>
//
// The `data-dynamic-content-show-param` and
// `data-dynamic-content-hide-param` attributes are used to
// specify the elements that will be shown and hidden when
// the event is triggered.
//
// More than one element can be specified by separating the
// selectors with a space.
//
// Optionally, the `data-dynamic-content-selection-class`
// attribute can be used to specify the class to be toggled
// on the element which triggered the event. See the
// dynamic_content_tags partial for an example of this.
//
// Deep Linking (hash links)
// -------------------------
//
// This can be achieved by creating a link to the
// "data-dynamic-content-show-param" value. For example, if
// the data-dynamic-content-show-param value is
// "#foo", you can trigger a click on it and scroll
// the matching trigger element into view.
//
//  Example: <a href="#foo" data-action="dynamic-content#jumpTo">
//
// If a page referring the dynamic-content-controller is
// loaded while having a hash in the URL already, that matches a
// "data-dynamic-content-show-param" value, the same thing
// will happen automatically after a short delay.
//
// Content Options
// ---------------
//
// The `data-dynamic-content-options` attribute can be used
// to specify options for display.
//
// Current options:
//   - 'fade-in' VALUE[fade duration]
//      example: "fade-in 0.5s"
//   - 'expand-vertically' VALUE[expansion duration]
//      example: "expand-vertically 0.5s"
//   - 'remove' VALUE[target CSS class or id]
//      examples: "remove .some-class", "remove #some-id"
//
// Examples:
//
// <div id="my-element" style="display: none;"
//   data-dynamic-content-options="fade-in 0.5s">
//
// <div id="my-element" style="display: none;"
//   data-dynamic-content-options="remove .some-class">
//
// Events
// ------
//
// You can listen for the `dynamic-content:show` and
// `dynamic-content:hide` events on the elements that are
// shown and hidden, to trigger additional actions when an
// element is shown or hidden.
//

// Connects to data-controller="dynamic-content"
export default class extends Controller {
  connect() {
    if (!window.location.hash.length) { return; }

    setTimeout(() => { this.jumpTo(false); }, 100);
  }

  // Not sure why the other methods aren't triggering
  // eslint... unless they're ignored elsewhere?
  // eslint-disable-next-line class-methods-use-this
  jumpTo(event) {
    if (event) { event.preventDefault(); }

    const attribute = 'data-dynamic-content-show-param';

    const trigger = (event?.currentTarget) ?
      document.querySelector(
        `[${attribute}='${event.currentTarget.hash}']`,
      ) : document.querySelector(
        `[${attribute}='${window.location.hash}']`,
      );
    trigger.click();
    trigger.scrollIntoView();
  }

  update(event) {
    event.preventDefault();
    let showElements = [];
    let hideElements = [];
    const trigger = this.element;
    const { currentTarget } = event;

    if (event.params.show !== undefined) {
      showElements = trigger.
        querySelectorAll(event.params.show.split(' '));
      showElements.forEach(element => {
        element.style.display = 'block';
        element.classList.remove('u-hidden');
        processOptionsForShowElement(element);
        dispatchEvent(element, 'dynamic-content:show');
      });

      // (event.preventDefault()) breaks native hash functionality
      // This allows the browser to reset the viewport per the
      // hash value of the trigger to bring the clicked item
      // into view
      if (currentTarget.hash.length > 1) {
        window.location.href = currentTarget.hash;

        // Also remove the hash after, so that page refresh
        // doesn't cause viewport to scroll to a hidden div
        window.history.replaceState(
          null, document.title, window.location.href.split('#')[0],
        );
      }

      // Also, indicate the selection of the trigger that was
      // interacted with (if this optional feature was requested)
      if (currentTarget.dataset.dynamicContentSelectionClass !== undefined) {
        const selectionClass = currentTarget.
          dataset.dynamicContentSelectionClass;

        currentTarget.
          closest("[data-controller='dynamic-content']").
          querySelectorAll(`.${selectionClass}`).
          forEach(c  => { c.classList.remove(selectionClass); });
        currentTarget.classList.toggle(
          currentTarget.dataset.dynamicContentSelectionClass);
      }
    }

    if (event.params.hide !== undefined) {
      hideElements = trigger.
        querySelectorAll(event.params.hide.split(' '));
      hideElements.forEach(element => {
        element.style.display = 'none';
        element.classList.add('u-hidden');
        dispatchEvent(element, 'dynamic-content:hide');
      });
    }

    if (showElements.length === 0 && hideElements.length === 0) {
      console.warn(
        'No valid selectors for elements to show or hide were ' +
        'specified in call to dynamic-content update.',
      );
    }

    fixAlternatingPattern();
  }
}

// Helper functions
// ----------------

// Dispatches an event on an element.
function dispatchEvent(element, eventName) {
  const event = new CustomEvent(eventName,
    { detail: { element: element } },
  );
  element.dispatchEvent(event);
}

// Processes options for showing an element.
function processOptionsForShowElement(element) {
  const options = element.dataset.dynamicContentOptions;
  if (options === undefined) {
    return;
  }
  options.split(';').forEach(fullOption => {
    const [option, value] = fullOption.split(' ');
    switch (option) {
      case 'fade-in':
        fadeIn(element, value === undefined ? '0.3s' : value);
        break;
      case 'expand-vertically':
        expandVertically(element,
          value === undefined ? '0.2s' : value,
        );
        break;
      case 'remove':
        if (typeof value === 'undefined') {
          this.trigger.remove();
          break;
        }
        document.querySelectorAll(value).forEach(t => t.remove());
        break;
      default:
        console.warn(
          `Unknown option for showing element: ${option}`,
        );
    }
  });
}

// "Fades" in an element using CSS properties.
function fadeIn(element, duration) {
  element.style.opacity = 0;
  element.style.transition = `opacity ${duration} ease-in-out`;
  setTimeout(() => {
    element.style.opacity = 1;
  }, 10);
}

// Expands an element vertically using CSS properties.
function expandVertically(element, duration) {
  element.style.maxHeight = '0';
  element.style.overflow = 'hidden';
  element.style.transition = `max-height ${duration} ease-in-out`;
  setTimeout(() => {
    element.style.maxHeight = `${element.scrollHeight}px`;
  }, 25);
}

// Ensures that alternating areas have the correct pattern after
// filtering.
function fixAlternatingPattern() {
  const visibleElements = document.querySelectorAll('.o-area--alternating:not(.u-hidden)');

  visibleElements.forEach((element, index) => {
    if (index % 2 === 1) {
      element.style.backgroundColor = '#EEF3F8'; // csa-color-gray-25
    } else {
      element.style.backgroundColor = '#FFFFFF'; // csa-color-white
    }
  });
}
