import { Controller } from '@hotwired/stimulus';
import { Loader } from '@googlemaps/js-api-loader';

//
// This controller is used to display a Google Map with custom styling
// and interactive markers.
//
// Usage
// -----
//
// To use this controller, you need to add a `div` element with the
// `data-controller="google-map"` attribute.
// SLIM:
//
// div data-controller="google-map"
//   data-google-map-markers='[
//     {
//       "coordinates": { "lat": ..., "lng": ... },
//       "content": { "name": ..., "url": ... }
//     },
//     ...
//   ]'
//   data-google-map-styles='[
//     { "featureType": "water", "stylers": [{ "color": "#46bcec" }] },
//     ...
//   ]'
//   data-google-map-center='{"lat": 47.6061, "lng": -122.3321}'
//   data-google-map-zoom="4"
//   data-google-id="GOOGLE_MAPS_API_KEY"
//
//   NOTE: Google Maps API keys are usually considered public-facing keys
//         because they are meant to be used client-slide. They're ok to
//         expose in your HTML if you restrict their usage to specific domains.
//
export default class extends Controller {
  connect() {
    this.loadGoogleMapsAPI();
  }

  async loadGoogleMapsAPI() {
    const apiKey = this.element.dataset.id;

    if (!apiKey) {
      console.error('Google Maps API key is missing');
      return;
    }

    const loader = new Loader({
      apiKey: apiKey,
      version: 'weekly',
    });

    try {
      await loader.importLibrary('maps');
      await loader.importLibrary('marker');
      this.initializeGoogleMap();
    } catch (error) {
      console.error('Error loading Google Maps API', error);
    }
  }

  initializeGoogleMap() {
    const mapElement = this.element; // DOM element
    const mapData = mapElement.dataset;

    const options = {
      streetViewControl: false,
      mapTypeControl: false,
      zoom: mapData.zoom || 2,
      center: mapData.center ? JSON.parse(mapData.center) : { lat: 0, lng: 0 },
      mapId: '96d4170fc51d5f5e', // Map ID is required for advanced markers
    };

    const map = new google.maps.Map(
      mapElement,
      options,
    );

    this.applyMapStyles(map, mapData.styles);
    this.addMarkers(map, mapData.markers);
  }

  // eslint-disable-next-line class-methods-use-this
  applyMapStyles(map, styles) {
    if (!styles || !map) return;

    const styledMapStyle = new google.maps.StyledMapType(JSON.parse(styles));
    map.mapTypes.set('styled_map', styledMapStyle);
    map.setMapTypeId('styled_map');
  }

  // eslint-disable-next-line class-methods-use-this
  addMarkers(map, markerData) {
    if (!markerData || !map) return;

    const infoWindow = new google.maps.InfoWindow();
    JSON.parse(markerData).forEach(({ coordinates, content }) => {
      const pinBackground = new google.maps.marker.PinElement({
        background: '#F98526',
        borderColor: '#F25D00',
        glyphColor: '#F25D00',
      });

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: new google.maps.LatLng(coordinates.lat, coordinates.lng),
        map,
        title: content.name, // tooltip text shown on hover
        content: pinBackground.element,
        gmpClickable: true,
      });

      marker.addListener('gmp-click', () => {
        infoWindow.close();
        infoWindow.setContent(this.createInfoWindowContent(content));
        infoWindow.open(map, marker);
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  createInfoWindowContent(content) {
    const div = document.createElement('div');
    const p = document.createElement('p');
    const a = document.createElement('a');
    const baseUrl = '/interactions/log_and_redirect_to';
    const queryParams = `?url=${encodeURIComponent(content.url)}` +
      `&interactable_type=Chapter&interactable_id=${content.id}`;

    p.textContent = content.name;
    p.style.fontWeight = 'bold';
    a.href = baseUrl + queryParams;
    a.textContent = 'Learn more →';

    div.appendChild(p);
    div.appendChild(a);

    return div;
  }
}
